.App {
    height: 90vh;
}

.right {
    justify-content: flex-end !important;
    padding-left: 50px
}
.left {
    justify-content: flex-start !important;
    padding-right: 50px
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.txnCharges input {
    background-color: white;
}

.txnCharges input[readonly] {
    background-color: inherit;
}
